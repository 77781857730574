import {Action, Reducer} from 'redux';
import {GlobalAction} from '../constants/actions';
import {Doc} from '../api/interface/mongo';
import {CustomerBase, CustomerDetail, CustomerContactInfo} from '../api/interface/customer';

type Types = 'customer/LIST' | 'customer/DETAIL' | 'customer/SELECT' | 'customer/LIST_CONTACT_INFO';

interface IListAction extends Action<Types> {
	type: 'customer/LIST';
	org: Doc;
	customerList: CustomerBase[];
}

interface IContactInfoListAction extends Action<Types> {
	type: 'customer/LIST_CONTACT_INFO';
	org: Doc;
	customerContactInfoList: CustomerContactInfo[];
}

interface IDetailAction extends Action<Types> {
	type: 'customer/DETAIL';
	org: Doc;
	customer: CustomerDetail | undefined;
}

export type CustomerAction = IListAction | IDetailAction | IContactInfoListAction;

export interface IState {
	customerList: Record<string, CustomerBase[]>;
	customer: Record<string, CustomerDetail | undefined>;
	customerContactInfoList: Record<string, CustomerContactInfo[]>;
}

export const initialState: IState = {
	customer: {},
	customerList: {},
	customerContactInfoList: {},
};

export const reducer: Reducer<IState, CustomerAction | GlobalAction> = (state = initialState, action) => {
	switch (action.type) {
		case 'customer/LIST': {
			const customerList = {...state.customerList};
			customerList[action.org._id] = action.customerList;
			return {
				...state,
				customerList,
			};
		}
		case 'customer/LIST_CONTACT_INFO': {
			const customerContactInfoList = {...state.customerContactInfoList};
			customerContactInfoList[action.org._id] = action.customerContactInfoList;
			return {
				...state,
				customerContactInfoList,
			};
		}
		case 'customer/DETAIL': {
			const customer = {...state.customer};
			if (action.customer) {
				customer[action.org._id] = action.customer;
			} else {
				delete customer[action.org._id];
			}
			return {
				...state,
				customer,
			};
		}
		case 'global/LOGOUT':
			return initialState;
		case 'global/RESET':
			return initialState;
		default:
			return state;
	}
};
