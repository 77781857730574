import React, {Suspense} from 'react';
import {Switch, Route, HashRouter as Router} from 'react-router-dom';
// import {withStyles, CssBaseline, IconButton, Snackbar, useTheme} from '@material-ui/core'
import {withTranslation, WithTranslation} from 'react-i18next';
import {withStyles, LinearProgress, Theme, WithStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {IReduxState, RootThunkDispatch} from './reducers';
import PrivateRoute from './components/PrivateRoute';
import ErrorBoundary from './components/ErrorBoundary';
import {withServiceWorker, IWithServiceWorker} from './ServiceWorkerProvider';
import ErrorView from './views/ErrorView';
import {appError, appShowHelp} from './actions/appActions';
import {doLogout} from './actions/authActions';
// import ReloadDialog from './components/ReloadDialog';
import TopMenu from './components/TopMenu';
import {needToRelogin, startTokenTimer} from './actions/authActions';
import ErrorDialog from './components/ErrorDialog';
import HelpDialog from './components/HelpDialog';
import LoginDialog from './components/LoginDialog';

const Loading = () => <LinearProgress color="secondary" />;
const HomeView = React.lazy(() => import('./views/Home' /* webpackChunkName: "home-view" */));
const LoginView = React.lazy(() => import('./views/Login' /* webpackChunkName: "login-view" */));
const BrokenView = React.lazy(() => import('./views/Broken' /* webpackChunkName: "broken-view" */));
const OrganizationView = React.lazy(() => import('./views/Organization' /* webpackChunkName: "org-view" */));
const AdminOrganizationView = React.lazy(() => import('./views/admin/OrganizationView' /* webpackChunkName: "admin-org-view" */));
const AdminOrganizationEdit = React.lazy(() => import('./views/admin/OrganizationEdit' /* webpackChunkName: "org-edit" */));
const UserOrganizationView = React.lazy(() => import('./views/OrganizationView' /* webpackChunkName: "user-org-view" */));
const SettingsUserView = React.lazy(() => import('./views/SettingsUser' /* webpackChunkName: "settings-user-view" */));
const CustomerSelectionView = React.lazy(() => import('./views/CustomerSelection' /* webpackChunkName: "customer-selection-view" */));
const TreatmentView = React.lazy(() => import('./views/Treatment' /* webpackChunkName: "treatment-view" */));
const CustomerEditView = React.lazy(() => import('./views/CustomerEditor' /* webpackChunkName: "customer-edit-view" */));
const PasswordResetView = React.lazy(() => import('./views/PasswordReset' /* webpackChunkName: "password-reset-view" */));
const AdminMembersView = React.lazy(() => import('./views/admin/MembersView' /* webpackChunkName: "admin-members-view" */));
const CustomerListView = React.lazy(() => import('./views/CustomerList' /* webpackChunkName: "customer-list-view" */));
const ReportView = React.lazy(() => import('./views/Reports' /* webpackChunkName: "report-view" */));

const CustomerList = () => (
	<Suspense fallback={<Loading />}>
		<CustomerListView />
	</Suspense>
);

const AdminMembers = () => (
	<Suspense fallback={<Loading />}>
		<AdminMembersView />
	</Suspense>
);

const Home = () => (
	<Suspense fallback={<Loading />}>
		<HomeView />
	</Suspense>
);

const Login = () => (
	<Suspense fallback={<Loading />}>
		<LoginView />
	</Suspense>
);

const Broken = () => (
	<Suspense fallback={<Loading />}>
		<BrokenView />
	</Suspense>
);

const Organization = () => (
	<Suspense fallback={<Loading />}>
		<OrganizationView />
	</Suspense>
);

const ViewAdminOrganization = () => (
	<Suspense fallback={<Loading />}>
		<AdminOrganizationView />
	</Suspense>
);

const CustomerSelection = () => (
	<Suspense fallback={<Loading />}>
		<CustomerSelectionView />
	</Suspense>
);

const Treatment = () => (
	<Suspense fallback={<Loading />}>
		<TreatmentView />
	</Suspense>
);

const EditAdminOrganization = () => (
	<Suspense fallback={<Loading />}>
		<AdminOrganizationEdit />
	</Suspense>
);

const ViewUserOrganization = () => (
	<Suspense fallback={<Loading />}>
		<UserOrganizationView />
	</Suspense>
);

const ViewSettingsUser = () => (
	<Suspense fallback={<Loading />}>
		<SettingsUserView />
	</Suspense>
);

const CustomerEdit = () => (
	<Suspense fallback={<Loading />}>
		<CustomerEditView />
	</Suspense>
);
const PasswordReset = () => (
	<Suspense fallback={<Loading />}>
		<PasswordResetView />
	</Suspense>
);
const Reports = () => (
	<Suspense fallback={<Loading />}>
		<ReportView />
	</Suspense>
);

const styles = (theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center' as const,
		color: theme.palette.text.secondary,
	},
});

type Props = WithTranslation & IPropsState & ActionList & IWithServiceWorker & WithStyles;

interface IState {
	modalVisible: boolean;
}

class App extends React.Component<Props, IState> {
	static getDerivedStateFromProps(props: Props, state: IState) {
		const {modalVisible} = state;
		const {serviceWorkerState} = props;
		if (serviceWorkerState && serviceWorkerState === 'installed' && modalVisible === false) {
			console.log('new version is waiting');
			return {modalVisible: true};
		}
		return null;
	}

	constructor(props: Props) {
		super(props);
		let modalVisible = false;
		if (props.serviceWorkerState && props.serviceWorkerState === 'installed') {
			modalVisible = true;
		}
		this.state = {
			modalVisible,
		};
		this.handleLogout = this.handleLogout.bind(this);
		this.handleError = this.handleError.bind(this);
	}

	componentDidMount() {
		this.props.i18n.changeLanguage('fi'); // currently hardcoded
		this.props.appError(undefined);
		if (this.props.needToRelogin()) {
			console.log('false from haveValidLogin');
			// this.handleLogout();
		}
		this.props.startTokenTimer();
	}

	async handleLogout() {
		try {
			await this.props.doLogout();
			window.location.href = '/#/login';
		} catch (err) {
			// ignore
		}
	}
	handleError() {
		this.props.appError(undefined);
	}
	render() {
		const {timeout, isLoggedIn, isLoading, error, classes, userInfo, showHelp, appShowHelp} = this.props;
		// const {modalVisible} = this.state;
		console.log('serviceWorkerState', this.props.serviceWorkerState);

		return (
			<div className={classes.root} style={{margin: 0, padding: 0}}>
				<Router>
					<ErrorBoundary onError={ErrorView}>
						<TopMenu
							userInfo={userInfo}
							isLoggedIn={isLoggedIn}
							onLogout={this.handleLogout}
							onShowHelp={() => {
								appShowHelp(true);
							}}
						/>
						<div>
							{/* <ReloadDialog open={modalVisible} /> */}
							<ErrorDialog open={error ? true : false} error={error} onClose={this.handleError} />
							<HelpDialog
								open={showHelp}
								onClose={() => {
									appShowHelp(false);
								}}
							/>
							<LoginDialog open={timeout} />
							<LinearProgress color="secondary" hidden={!isLoading} />
							<Switch>
								<Route exact={true} path="/" component={Home} />
								<Route exact={true} path="/login" component={Login} />
								<Route exact={true} path="/forget/:token" component={PasswordReset} />
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/org" component={Organization} />
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/admin/members" component={AdminMembers} />
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/admin/org" component={ViewAdminOrganization} />
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/admin/org/:orgId" component={EditAdminOrganization} />
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/org/:orgId" component={ViewUserOrganization} />
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/settings" component={ViewSettingsUser} />
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/org/:orgId/customer/" component={CustomerSelection} />
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/org/:orgId/customers/" component={CustomerList} />
								<PrivateRoute
									isValid={isLoggedIn}
									failPath="/login"
									exact={true}
									path="/org/:orgId/customer/:customerId/treatment/:treatmentId"
									component={Treatment}
								/>
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/org/:orgId/reports/" component={Reports} />
								<PrivateRoute isValid={isLoggedIn} failPath="/login" exact={true} path="/org/:orgId/editcustomer/:mode/" component={CustomerEdit} />
								<Route exact={true} path="/broken" component={Broken} />
								<Route exact={true} path="/_error" component={ErrorView} />
							</Switch>
						</div>
					</ErrorBoundary>
				</Router>
			</div>
		);
	}
}

const mapStateToProps = (state: IReduxState) => {
	return {
		error: state.app.error,
		isLoading: state.app.isLoading,
		isLoggedIn: state.auth.accessToken ? true : false,
		userInfo: state.auth.userInfo || undefined,
		showHelp: state.app.showHelp,
		timeout: state.auth.timeout || false,
	};
};
type IPropsState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: RootThunkDispatch) =>
	bindActionCreators(
		{
			appError,
			startTokenTimer,
			doLogout,
			needToRelogin,
			appShowHelp,
		},
		dispatch,
	);

type ActionList = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withServiceWorker(withStyles(styles)(App))));
