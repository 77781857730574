import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {IReduxState, ThunkResult} from '../reducers';
import {isHttpError} from '../api/interface/error';
import i18next from 'i18next';

export const handleJsonResponse = <T>(
	res: Response | undefined,
	unAuthorizedAction?: () => ThunkAction<Promise<void>, IReduxState, undefined, Action<any>>,
): ThunkResult<Promise<T | undefined>> => async (dispatch) => {
	if (!res) {
		return undefined;
	}
	let text = 'http error: ' + res.status;
	let payload;

	const contentType = res.headers.get('Content-type');
	if (contentType && contentType.startsWith('application/json')) {
		payload = await res.json();
	}
	if (isHttpError(payload)) {
		text = i18next.t('error:' + payload.error) + (payload.description ? `: ${i18next.t('error:' + payload.description)}` : '');
	}

	switch (res.status) {
		case 200:
			return Promise.resolve(payload);
		case 304:
			return Promise.resolve(undefined);
		case 401: {
			if (dispatch && unAuthorizedAction) {
				// console.log('dispatch unAuthorizedAction');
				await dispatch(unAuthorizedAction());
				// window.location.hash = '#/login';
				return Promise.resolve(undefined);
			}
			return Promise.reject(new Error(text));
		}
		default:
			return Promise.reject(new Error(text));
	}
};
