export const podopointWhite = '#FFFFFF';
export const podopointAlmostWhite = '#F9F9F9';
export const podopointTextPrimary = '#0083C5'; // blue
export const podopointTextSecondary = '#002131'; // black
export const podopointTextMinor = '#909090';
export const podopointPrimary = '#0083C5'; // blue
export const podopointSecondary = '#002131'; // black
export const podopointDarkBlue = '#00192D';
export const podopointDarkBlueSelected = '#002643';
export const podopointLightRed = '#DB9CAF';
export const podopointRed = '#A60838';
export const podopointYellow = '#FFF26E';
export const podopointGreen = '#00AD8A';
export const podopointGrid = '#d9edf8';
export const podopointWarning = '#F2865E';
export const podopointTopMenuHighLight = '#F8C9B6';
