import {handleJsonResponse} from '.';
import {getApiBase} from '../lib/configTools';
import {httpFetch} from '../lib/httpClient';
import {ThunkResult} from '../reducers';
import {AppAction, IStatusData} from '../reducers/appReducer';
import {authLogout} from './authActions';
import {cacheMatch, cacheStore, isOnline} from '../lib/commonCache';

export const appShowHelp = (showHelp: boolean): AppAction => ({type: 'app/SHOW_HELP', showHelp});
export const appLoading = (isLoading: boolean): AppAction => ({type: 'app/LOADING', isLoading});
export const appData = (status: IStatusData | undefined): AppAction => ({type: 'app/DATA', status});
export const appError = (error: Error | string | undefined): AppAction => {
	if (!error) {
		return {type: 'app/ERROR', error};
	}
	if (typeof error === 'string') {
		return {type: 'app/ERROR', error};
	} else {
		return {type: 'app/ERROR', error: error.message};
	}
};

export const getStatus = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	try {
		const headers = new Headers();
		const req = new Request(`${await getApiBase()}/status`, {headers});
		let res = await cacheMatch(req, {ifNoneMatch: true});
		// do cache render
		const cacheData = await dispatch(handleJsonResponse<IStatusData>(res, authLogout));
		if (cacheData) {
			dispatch(appData(cacheData));
		}
		if (isOnline()) {
			res = await httpFetch(req);
			await cacheStore(req, res);
			const data = await dispatch(handleJsonResponse<IStatusData>(res, authLogout));
			if (data) {
				dispatch(appData(data));
			}
		}
	} catch (err) {
		dispatch(appError(err));
	}
};
