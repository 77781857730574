import {Action, Reducer} from 'redux';
import {TreatmentDetail, TreatmentBase} from '../api/interface/treatment';
import {Doc} from '../api/interface/mongo';
import {GlobalAction} from '../constants/actions';

type Types = 'treatment/READ' | 'treatment/LIST';

interface ITreatmentReadAction extends Action<Types> {
	type: 'treatment/READ';
	customer: Doc;
	treatment: TreatmentDetail;
}
interface ITreatmentListAction extends Action<Types> {
	type: 'treatment/LIST';
	customer: Doc;
	treatmentList: TreatmentBase[];
}

export type TreatmentAction = ITreatmentReadAction | ITreatmentListAction;

export interface IState {
	treatments: Record<string, Record<string, TreatmentDetail>>;
	treatmentList: Record<string, TreatmentBase[]>;
}

export const initialState: IState = {
	treatments: {},
	treatmentList: {},
};

export const reducer: Reducer<IState, TreatmentAction | GlobalAction> = (state = initialState, action) => {
	switch (action.type) {
		case 'treatment/READ': {
			const treatments = {...state.treatments};
			if (!treatments[action.customer._id]) {
				treatments[action.customer._id] = {};
			}
			treatments[action.customer._id][action.treatment._id] = action.treatment;
			return {
				...state,
				treatments,
			};
		}
		case 'treatment/LIST': {
			const treatmentList = {...state.treatmentList};
			treatmentList[action.customer._id] = action.treatmentList;
			return {
				...state,
				treatmentList,
			};
		}
		case 'global/LOGOUT':
			return initialState;
		case 'global/RESET':
			return initialState;
		default:
			return state;
	}
};
