import {OrgAction} from '../reducers/orgReducer';
import {getApiBase} from '../lib/configTools';
import {handleJsonResponse} from '.';
import {appError} from './appActions';
import {authLogout, getAccessToken} from './authActions';
import {httpFetch} from '../lib/httpClient';
import {ThunkResult} from '../reducers';
import {Doc} from '../api/interface/mongo';
import {OrgMember, OrgInfo} from '../api/interface/org';
import {cacheMatch, cacheStore, cleanCache, isOnline} from '../lib/commonCache';

export const orgUserListAction = (userOrgList: OrgInfo[]): OrgAction => ({type: 'org/USER_LIST', userOrgList});
export const orgUserListMembersAction = (userOrgMembers: OrgMember[]): OrgAction => ({type: 'org/USER_MEMBERS', userOrgMembers});

export const getUserOrgList = (): ThunkResult<Promise<OrgInfo[] | undefined>> => async (dispatch, getState) => {
	try {
		// request
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + (await dispatch(getAccessToken())));
		const req = new Request(`${await getApiBase()}/org`, {headers});
		// do cache fetch and render
		let res = await cacheMatch(req, {ifNoneMatch: true});
		const cacheData = await dispatch(handleJsonResponse<OrgInfo[]>(res, authLogout));
		if (isOnline()) {
			// do online fetch and render if data
			res = await httpFetch(req);
			if (res.status === 200) {
				await cacheStore(req, res);
				const data = await dispatch(handleJsonResponse<OrgInfo[]>(res, authLogout));
				if (data) {
					dispatch(orgUserListAction(data));
					// clear org based customerlist
					let base = `${await getApiBase()}/org/customer/`;
					await cleanCache(
						base,
						data.map((e) => `${base}${e._id}`),
					);
					// clear org based userlist
					base = `${await getApiBase()}/org/user/`;
					await cleanCache(
						base,
						data.map((e) => `${base}${e._id}`),
					);
					return data;
				}
			} else if (res.status === 304 &&  cacheData) {
				return cacheData;
			}
		} else {
			if (cacheData) {
				dispatch(orgUserListAction(cacheData));
				return cacheData;
			}
		}
	} catch (err) {
		dispatch(appError(err));
	}
	return;
};

export const getUserOrgMemberList = ({_id}: Doc): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	try {
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + (await dispatch(getAccessToken())));
		const req = new Request(`${await getApiBase()}/org/user/${_id}`, {headers});
		let res = await cacheMatch(req, {ifNoneMatch: true});
		// do cache render
		const cacheData = await dispatch(handleJsonResponse<OrgMember[]>(res, authLogout));
		if (cacheData) {
			dispatch(orgUserListMembersAction(cacheData));
		}
		if (isOnline()) {
			res = await httpFetch(req);
			await cacheStore(req, res);
			const data = await dispatch(handleJsonResponse<OrgMember[]>(res, authLogout));
			if (data) {
				dispatch(orgUserListMembersAction(data));
			}
		}
	} catch (err) {
		dispatch(appError(err));
	}
};

export interface IOrgMemberCreate {
	name: string;
	email: string;
	password: string;
	roleCollection: string;
}
export const orgMemberCreate = ({_id}: Doc, {name, email, password, roleCollection}: IOrgMemberCreate): ThunkResult<Promise<void>> => async (
	dispatch,
	getState,
) => {
	try {
		const body = JSON.stringify({name, email, password, roleCollection});
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + (await dispatch(getAccessToken())));
		headers.set('Content-type', 'application/json');
		headers.set('Content-length', '' + body.length);
		const res = await httpFetch(`${await getApiBase()}/org/user/${_id}`, {headers, body, method: 'POST'});
		const data = await dispatch(handleJsonResponse<any>(res, authLogout));
		if (data) {
			await dispatch(getUserOrgMemberList({_id}));
		}
	} catch (err) {
		dispatch(appError(err));
	}
};
export interface IOrgMemberAdd {
	email: string;
	roleCollection: string;
}
export const orgMemberAdd = ({_id}: Doc, {email, roleCollection}: {email: string; roleCollection: string}): ThunkResult<Promise<void>> => async (
	dispatch,
	getState,
) => {
	try {
		const body = JSON.stringify({email, roleCollection});
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + (await dispatch(getAccessToken())));
		headers.set('Content-type', 'application/json');
		headers.set('Content-length', '' + body.length);
		const res = await httpFetch(`${await getApiBase()}/org/user/${_id}`, {headers, body, method: 'PUT'});
		const data = await dispatch(handleJsonResponse<any>(res, authLogout));
		if (data) {
			await dispatch(getUserOrgMemberList({_id}));
		}
	} catch (err) {
		dispatch(appError(err));
	}
};

export const orgMemberRemove = ({_id}: Doc, {email}: {email: string}): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	try {
		const body = JSON.stringify({email});
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + (await dispatch(getAccessToken())));
		headers.set('Content-type', 'application/json');
		headers.set('Content-length', '' + body.length);
		const res = await httpFetch(`${await getApiBase()}/org/user/${_id}`, {headers, body, method: 'DELETE'});
		const data = await dispatch(handleJsonResponse<any>(res, authLogout));
		if (data) {
			await dispatch(getUserOrgMemberList({_id}));
		}
	} catch (err) {
		dispatch(appError(err));
	}
};

export const validEmail = ({_id}: Doc, email: string): ThunkResult<Promise<boolean>> => async (dispatch, getState) => {
	try {
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + (await dispatch(getAccessToken())));
		const res = await httpFetch(`${await getApiBase()}/org/user/${_id}/email/${email}`, {headers});
		const data = await dispatch(handleJsonResponse<any>(res, authLogout));
		if (data && data._id) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		dispatch(appError(err));
		return false;
	}
};
