import * as jwt from 'jsonwebtoken';
import {AuthAction} from '../reducers/authReducer';
import {handleJsonResponse} from '.';
import {appError} from './appActions';
import {getApiBase, getClientId} from '../lib/configTools';
import {httpFetch} from '../lib/httpClient';
import {ThunkResult} from '../reducers';
import {IUserInfo} from '../api/interface/user';
import {ITokenResponse} from '../api/interface/token';
import {getUserOrgList} from './orgActions';
import {cacheMatch, cacheStore, isOnline, deleteCache} from '../lib/commonCache';
import {Doc} from '../api/interface/mongo';
import {OrgRoles, GlobalRole} from '../api/interface/roles';
import {doLogoutAction} from './globalActions';
import {AUTH_COMPLETE, AUTH_PHASE_1, AUTH_PHASE_2, HTTP_402_PAYMENT_REQUIRED} from 'src/constants/auth';

export const authLogin = (accessToken: string, refreshToken: string | undefined): AuthAction => ({type: 'auth/LOGIN', accessToken, refreshToken});
export const authUserInfo = (userInfo: IUserInfo | undefined): AuthAction => ({type: 'auth/USER_INFO', userInfo});
export const authRoleList = (roleList: GlobalRole[]): AuthAction => ({type: 'auth/ROLES', roleList});
export const authOrgRole = (org: Doc, role: OrgRoles): AuthAction => ({type: 'auth/ORG_ROLES', org, role});
export const authRememberMe = (rememberMe: boolean): AuthAction => ({type: 'auth/REMEMBER', rememberMe});
export const authEmail = (userEmail: string | undefined): AuthAction => ({type: 'auth/EMAIL', userEmail});
export const authTimeout = (timeout: boolean): AuthAction => ({type: 'auth/TIMEOUT', timeout});

let expireIn: number | undefined;

declare global {
	interface Window {
		accessTokenExpire: (value?: number) => number | undefined;
	}
}
window.accessTokenExpire = function (value?: number) {
	if (value && Number.isInteger(value)) {
		console.info(`request accessToken expire to ${value} seconds`);
		expireIn = value;
	}
	return expireIn;
};

export const doLogout = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	try {
		const {
			auth: {accessToken, refreshToken},
		} = getState();
		// clear logout timer
		if (logoutTimer) {
			console.log('clearout logout timer');
			clearTimeout(logoutTimer);
		}
		if (refreshToken || accessToken) {
			const headers = new Headers();
			headers.set('Content-Type', 'application/json');
			const body = JSON.stringify({
				refresh_token: refreshToken,
				access_token: accessToken,
			});
			headers.set('Content-Length', '' + body.length);
			const res = await httpFetch(`${await getApiBase()}/token/logout`, {headers, method: 'POST', body});
			await dispatch(handleJsonResponse<{}>(res));
			await deleteCache();
		}
		dispatch(doLogoutAction());
	} catch (err) {
		dispatch(appError(err));
	}
};

export const authLogout = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	dispatch(authTimeout(true));
};

export const getUserInfo = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	try {
		// request
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + (await dispatch(getAccessToken())));
		const req = new Request(`${await getApiBase()}/me`, {headers});
		let res = await cacheMatch(req, {ifNoneMatch: true});
		// do cache render
		const cacheData = await dispatch(handleJsonResponse<IUserInfo>(res, authLogout));
		if (cacheData) {
			dispatch(authUserInfo(cacheData));
		}
		if (isOnline()) {
			// do online fetch and render if data
			res = await httpFetch(req);
			await cacheStore(req, res);
			const data = await dispatch(handleJsonResponse<IUserInfo>(res, authLogout));
			if (data) {
				dispatch(authUserInfo(data));
			}
		}
	} catch (err) {
		dispatch(appError(err));
	}
};

export const getUserGlobalRoleList = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	try {
		// request
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + (await dispatch(getAccessToken())));
		const req = new Request(`${await getApiBase()}/role`, {headers});
		let res = await cacheMatch(req, {ifNoneMatch: true});
		const cacheData = await dispatch(handleJsonResponse<GlobalRole[]>(res, authLogout));
		if (cacheData) {
			dispatch(authRoleList(cacheData));
		}
		if (isOnline()) {
			// do online fetch and render if data
			res = await httpFetch(req);
			await cacheStore(req, res);
			const data = await dispatch(handleJsonResponse<GlobalRole[]>(res, authLogout));
			if (data) {
				dispatch(authRoleList(data));
			}
		}
	} catch (err) {
		dispatch(appError(err));
	}
};

export const getUserOrgRoleList = (org: Doc): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	try {
		// request
		const headers = new Headers();
		headers.set('Authorization', 'Bearer ' + (await dispatch(getAccessToken())));
		const req = new Request(`${await getApiBase()}/role/${org._id}`, {headers});
		let res = await cacheMatch(req, {ifNoneMatch: true});
		const cacheData = await dispatch(handleJsonResponse<OrgRoles>(res, authLogout));
		if (isOnline()) {
			// do online fetch and render if data
			res = await httpFetch(req);
			await cacheStore(req, res);
			const data = await dispatch(handleJsonResponse<OrgRoles>(res, authLogout));
			if (data) {
				dispatch(authOrgRole(org, data));
			} else if (cacheData) {
				dispatch(authOrgRole(org, cacheData));
			}
		} else {
			// offline dispatch
			if (cacheData) {
				dispatch(authOrgRole(org, cacheData));
			}
		}
	} catch (err) {
		dispatch(appError(err));
	}
};

export const doLogin = (username: string, password: string, remember = false, verificationCode: number | null): ThunkResult<Promise<string>> => async (dispatch) => {
	try {
		dispatch(appError(undefined)); // reset error
		const tokenBody: any = {
			client_id: await getClientId(),
			username,
			password,
			scope: remember ? 'openid offline_access' : 'openid',
			grant_type: 'password',
			verificationCode: verificationCode
		};
		if (expireIn) {
			tokenBody.expireIn = expireIn;
		}
		const body = JSON.stringify(tokenBody);
		const headers = new Headers();
		headers.set('Content-type', 'application/json');
		headers.set('Content-length', '' + body.length);
		const res = await httpFetch(`${await getApiBase()}/token`, {headers, method: 'POST', body});
		if(res.status === HTTP_402_PAYMENT_REQUIRED){
			return AUTH_PHASE_2;
		}
		const data = await dispatch(handleJsonResponse<ITokenResponse>(res, doLogout));
		if (!data) {
			throw new Error('no data from token endpoint');
		}
		console.debug('token expires', data.expires_in);
		dispatch(authLogin(data.access_token, data.refresh_token));
		await Promise.all([dispatch(getUserInfo()), dispatch(getUserOrgList()), dispatch(getUserGlobalRoleList())]);
		dispatch(startTokenTimer());
		return AUTH_COMPLETE;
	} catch (err) {
		// dispatch(appError(err));
		return AUTH_PHASE_1;
	}
};

const getAccessTokenRefresh = (): ThunkResult<Promise<string>> => async (dispatch, getState) => {
	try {
		const {
			auth: {refreshToken},
		} = getState();
		dispatch(appError(undefined));
		if (!refreshToken) {
			dispatch(authLogout());
			throw new Error('session closed');
		}
		const headers = new Headers();
		const postbody = {
			grant_type: 'refresh_token',
			refresh_token: refreshToken,
			client_id: await getClientId(),
		};
		headers.set('Content-Type', 'application/json');
		const body = JSON.stringify(postbody);
		headers.set('Content-Length', '' + body.length);

		const res = await fetch((await getApiBase()) + '/token', {headers, method: 'POST', body});
		const loginPayload = await res.json();
		if (loginPayload) {
			console.debug('token expires', loginPayload.expires_in);
			dispatch(authLogin(loginPayload.access_token, loginPayload.refresh_token ? loginPayload.refresh_token : undefined));
			dispatch(startTokenTimer()); // basically this is useless, but but but ....
			return Promise.resolve(loginPayload.access_token);
		} else {
			throw new Error('login failed');
		}
	} catch (err) {
		dispatch(appError(err.message));
		throw err;
	}
};

export const isValidToken = (token: string | undefined): boolean => {
	const now = Math.floor(Date.now() / 1000);
	if (!token) {
		return false;
	}
	const decoded = jwt.decode(token) as {exp: number};
	if (decoded && decoded.exp > now) {
		return true;
	}
	return false;
};

export const getAccessToken = (): ThunkResult<Promise<string>> => async (dispatch, getState) => {
	let {
		auth: {accessToken},
	} = getState();
	if (!accessToken) {
		throw new Error('no access token');
	}
	if (!isValidToken(accessToken)) {
		accessToken = await dispatch(getAccessTokenRefresh());
	}
	return accessToken;
};

export const needToRelogin = (): ThunkResult<boolean> => (dispatch, getState) => {
	const {
		auth: {accessToken, refreshToken, loggedIn},
	} = getState();
	// we don't have token yet
	if (!accessToken && !refreshToken) {
		return false;
	}
	if (process.env.NODE_ENV === 'development') {
		console.log('accessToken still valid?', isValidToken(accessToken));
		console.log('refreshToken still valid?', isValidToken(refreshToken));
	}
	if (loggedIn && (isValidToken(accessToken) || isValidToken(refreshToken))) {
		return true;
	}
	return false;
};

export const passwordReset = (email: string): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	try {
		const body = JSON.stringify({email});
		const headers = new Headers();
		headers.set('Content-Type', 'application/json');
		headers.set('Content-Length', '' + body.length);
		const res = await fetch((await getApiBase()) + '/token/forget_password', {headers, method: 'POST', body});
		await dispatch(handleJsonResponse<any>(res));
	} catch (err) {
		dispatch(appError(err));
	}
};

export const passwordSave = (token: string, password: string): ThunkResult<Promise<boolean>> => async (dispatch, getState) => {
	try {
		const body = JSON.stringify({password});
		const headers = new Headers();
		headers.set('Content-Type', 'application/json');
		headers.set('Authorization', 'Bearer ' + token);
		headers.set('Content-Length', '' + body.length);
		const res = await fetch((await getApiBase()) + '/token/forget_password', {headers, method: 'PUT', body});
		await dispatch(handleJsonResponse<any>(res));
		return true;
	} catch (err) {
		dispatch(appError(err));
		return false;
	}
};

let logoutTimer: ReturnType<typeof setTimeout> | undefined;
export const startTokenTimer = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
	const {
		auth: {accessToken, refreshToken, loggedIn},
	} = getState();
	if (logoutTimer) {
		clearTimeout(logoutTimer);
	}
	if (!refreshToken && accessToken && loggedIn) {
		console.log('register token watcher');
		const now = Math.floor(Date.now() / 1000);
		const decoded = jwt.decode(accessToken) as {exp: number};
		if (decoded && decoded.exp > now) {
			const expSeconds = decoded.exp - now;
			logoutTimer = setTimeout(() => {
				console.log('token watcher timeout');
				dispatch(authTimeout(true));
			}, expSeconds * 1000);
		} else {
			console.log('setup login dialog');
		}
	}
};
