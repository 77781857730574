import React from 'react';
import {connect} from 'react-redux';
import {withTranslation, WithTranslation} from 'react-i18next';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {doLogin, authRememberMe, passwordReset, authEmail} from '../actions/authActions';
import {TextField, Button, Grid, withStyles, Typography, FormControlLabel, WithStyles, Container, Link, Checkbox, Snackbar} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import Alert from '@material-ui/lab/Alert';
import {IReduxState, RootThunkDispatch} from '../reducers';
import {styles} from '../style';
import { AUTH_COMPLETE, AUTH_PHASE_2 } from 'src/constants/auth';

interface IProps {
	type: 'initial' | 'timeout';
	onPasswordResetClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onGdprClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type Props = IProps & ActionList & IPropsState & WithTranslation & WithStyles & RouteComponentProps;

interface IState {
	username: string;
	password: string;
	rememberMe: boolean;
	showAlert: boolean;
	showVerificationCodeInput: boolean;
	verificationCode: string;
}

class LoginComponent extends React.Component<Props, IState> {
	constructor(props: Props) {
		super(props);
		this.state = {
			username: this.props.userEmail || '',
			password: '',
			rememberMe: this.props.rememberMe,
			showAlert: false,
			showVerificationCodeInput: false,
			verificationCode: '',
		};
		this.handleLogin = this.handleLogin.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
		this.handleAlertClose = this.handleAlertClose.bind(this);
	}

	render() {
		const {type, t, classes, onPasswordResetClick, onGdprClick} = this.props;
		const {username, password, rememberMe, verificationCode, showVerificationCodeInput} = this.state;
		return (
			<form onSubmit={() => false}>
				{/* Toast if login fails */}
				<Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={this.state.showAlert} autoHideDuration={4000} onClose={this.handleAlertClose}>
					<Alert onClose={this.handleAlertClose} severity="error">
						{t('error:wrong_username_or_password')}
					</Alert>
				</Snackbar>
				<Container maxWidth="xs">
					<Grid container={true} justify="center" direction="row">
						{type === 'initial' && (
							<Grid item={true} container={true}>
								<Typography className={classes.commonItalic} color="secondary" variant="h6">
									{t('login_wellcome')}
								</Typography>
							</Grid>
						)}
						<Grid item={true} container={true}>
							<TextField
								name="username"
								color="secondary"
								autoComplete="email"
								required={true}
								label={t('useraccount')}
								fullWidth={true}
								onChange={this.onChange}
								value={username}
							/>
						</Grid>
						<Grid item={true} container={true}>
							<TextField
								color="secondary"
								name="password"
								type="password"
								autoComplete="current-password"
								required={true}
								label={t('password')}
								fullWidth={true}
								onChange={this.onChange}
								onKeyUp={this.onKeyUp}
								value={password}
							/>
						</Grid>
						{showVerificationCodeInput && <Grid item={true} container={true}>
							<TextField
								color="secondary"
								name="verificationCode"
								type="password"
								required={true}
								label={t('verification_code')}
								fullWidth={true}
								onChange={this.onChange}
								onKeyUp={this.onKeyUp}
								value={verificationCode}
							/>
						</Grid>}
						{type === 'initial' && (
							<Grid item={true} container={true} justify="space-between">
								{onGdprClick && (
									<Link href="#" onClick={onGdprClick}>
										{t('gdpr_dialog')}
									</Link>
								)}
								{onPasswordResetClick && (
									<Link href="#" onClick={onPasswordResetClick}>
										{t('forgot_password')}
									</Link>
								)}
							</Grid>
						)}
						<Grid item={true} container={true} justify="center">
							<FormControlLabel
								classes={{label: classes.formLabel}}
								control={<Checkbox color="primary" checked={rememberMe} name="rememberMe" onChange={this.onChange} />}
								label={t('remember_me')}
							/>
						</Grid>
						<Button className={`${classes.loginButton} ${classes.orangeButton}`} onClick={this.handleLogin} variant="contained">
							{type === 'initial' ? t('login') : t('continue')}
						</Button>
					</Grid>
				</Container>
			</form>
		);
	}
	private handleAlertClose() {
		this.setState({showAlert: false});
	}

	private async handleLogin(event: React.MouseEvent | React.KeyboardEvent) {
		event.preventDefault();
		const {username, password, rememberMe, verificationCode, showVerificationCodeInput} = this.state;
		let mfaCode = null
		if (showVerificationCodeInput && verificationCode.length === 0) {
			return this.setState({showAlert: true})
		} else if (showVerificationCodeInput && verificationCode.length > 0) {
			mfaCode = Number(verificationCode)
		}
		const authPhase = await this.props.doLogin(username, password, rememberMe, mfaCode);

		if (authPhase === AUTH_COMPLETE) {
			this.props.authEmail(this.state.username);
			if (this.props.type === 'initial') {
				this.props.history.push('/org');
			}
		} else if (authPhase === AUTH_PHASE_2) {
			this.setState({showVerificationCodeInput: true})
		} else {
			this.setState({showAlert: true});
		}
	}

	private onChange({currentTarget}: React.ChangeEvent<HTMLInputElement>) {
		if (currentTarget && currentTarget.name !== undefined) {
			switch (currentTarget.name) {
				case 'username':
					return this.setState({username: currentTarget.value});
				case 'password':
					return this.setState({password: currentTarget.value});
				case 'rememberMe':
					this.props.authRememberMe(currentTarget.checked);
					return this.setState({rememberMe: currentTarget.checked});
				case 'verificationCode':
					return this.setState({verificationCode: currentTarget.value});
				default:
					break;
			}
		}
	}
	private onKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
		if (event.keyCode === 13) {
			this.handleLogin(event);
		}
		return false;
	}
}

const mapStateToProps = (state: IReduxState) => {
	return {
		rememberMe: state.auth.rememberMe,
		userEmail: state.auth.userEmail,
	};
};
type IPropsState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: RootThunkDispatch) =>
	bindActionCreators(
		{
			authEmail,
			authRememberMe,
			doLogin,
			passwordReset,
		},
		dispatch,
	);
type ActionList = ReturnType<typeof mapDispatchToProps>;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(LoginComponent))));
