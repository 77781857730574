import {Theme} from '@material-ui/core';
import {podopointGreen} from './constants/colors';
import {podopointGrid} from './constants/colors';

// common styles
export const styles = (theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	rootMaxHeight: {
		minHeight: 'calc(100vh - 64px)',
	},
	commonItalic: {
		fontStyle: 'italic',
	},
	commonPaper: {
		padding: theme.spacing(2),
		textAlign: 'center' as const,
		color: theme.palette.text.secondary,
	},
	gridItem: {
		padding: theme.spacing(1),
	},
	formLabel: {
		color: theme.palette.text.secondary,
	},
	loginButton: {
		width: '70%',
	},
	loginPaper: {
		maxWidth: 400,
		padding: theme.spacing(2),
		textAlign: 'center' as const,
		color: theme.palette.text.secondary,
	},
	loginImage: {
		backgroundImage: 'url(https://source.unsplash.com/random)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	saveButton: {
		backgroundColor: podopointGreen,
		color: 'white',
	},
	blueContainer: {
		backgroundColor: '#d9edf8',
		paddingTop: '20px', // !important',
		paddingBottom: '20px', // !important',
		// marginTop: '4px !important',
		// marginBottom: '4px !important',
	},
	bluePaper: {
		borderColor: podopointGrid,
		margin: '5px',
		flexGrow: 1,
	},
	blueBorderPaper: {
		borderColor: podopointGrid,
	},
	alignItemsRight: {
		alignItems: 'flex-end',
	},
	orangeLabel: {
		color: theme.buttons.orange.main,
	},
	wrapIcon: {
		display: 'flex',
		alignItems: 'center',
	},
	media: {
		height: 250,
		backgroundSize: 'contain',
	},
	dialogCloseButton: {
		position: 'absolute' as const,
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	greenButton: {
		color: theme.buttons.green.contrastText,
		backgroundColor: theme.buttons.green.main,
		'&:hover': {
			backgroundColor: theme.buttons.green.dark,
		},
		'&:disabled': {
			backgroundColor: theme.buttons.gray.light,
			color: theme.buttons.gray.dark,
		},
	},

	greenButtonOutlined: {
		color: theme.buttons.green.main,
		backgroundColor: 'transparent',
		borderColor: theme.buttons.green.main,
		'&:hover': {
			color: theme.buttons.green.dark,
			borderColor: theme.buttons.green.dark,
		},
		'&:disabled': {
			color: theme.buttons.gray.main,
			borderColor: theme.buttons.gray.main,
		},
	},

	blueButton: {
		color: theme.buttons.blue.contrastText,
		backgroundColor: theme.buttons.blue.main,
		'&:hover': {
			backgroundColor: theme.buttons.blue.dark,
		},
		'&:disabled': {
			backgroundColor: theme.buttons.gray.light,
			color: theme.buttons.gray.dark,
		},
	},
	blueButtonOutlined: {
		color: theme.buttons.blue.main,
		backgroundColor: 'transparent',
		borderColor: theme.buttons.blue.main,
		'&:hover': {
			color: theme.buttons.blue.dark,
			borderColor: theme.buttons.blue.dark,
		},
		'&:disabled': {
			color: theme.buttons.gray.main,
			borderColor: theme.buttons.gray.main,
		},
	},
	orangeButton: {
		color: theme.buttons.orange.contrastText,
		backgroundColor: theme.buttons.orange.main,
		'&:hover': {
			backgroundColor: theme.buttons.orange.dark,
		},
		'&:disabled': {
			backgroundColor: theme.buttons.gray.light,
			color: theme.buttons.gray.dark,
		},
	},
	redButton: {
		color: theme.buttons.red.contrastText,
		backgroundColor: theme.buttons.red.main,
		'&:hover': {
			backgroundColor: theme.buttons.red.dark,
		},
		'&:disabled': {
			backgroundColor: theme.buttons.gray.light,
			color: theme.buttons.gray.dark,
		},
	},
});
