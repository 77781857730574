import {Action, Reducer} from 'redux';
import {GlobalAction} from '../constants/actions';
import {IUserInfo} from '../api/interface/user';
import {OrgRoles, GlobalRole} from '../api/interface/roles';
import {Doc} from '../api/interface/mongo';

type Types = 'auth/LOGIN' | 'auth/USER_INFO' | 'auth/ROLES' | 'auth/ORG_ROLES' | 'auth/REMEMBER' | 'auth/EMAIL' | 'auth/TIMEOUT';

interface ILoginAction extends Action<Types> {
	type: 'auth/LOGIN';
	accessToken: string;
	refreshToken: string | undefined;
}

interface IRolesAction extends Action<Types> {
	type: 'auth/ROLES';
	roleList: GlobalRole[];
}

interface IOrgRolesAction extends Action<Types> {
	type: 'auth/ORG_ROLES';
	org: Doc;
	role: OrgRoles;
}

interface IUserInfoAction extends Action<Types> {
	type: 'auth/USER_INFO';
	userInfo: IUserInfo | undefined;
}

interface IEmailAction extends Action<Types> {
	type: 'auth/EMAIL';
	userEmail: string | undefined;
}
interface IRememberAction extends Action<Types> {
	type: 'auth/REMEMBER';
	rememberMe: boolean;
}

interface ITimeoutAction extends Action<Types> {
	type: 'auth/TIMEOUT';
	timeout: boolean;
}
export type AuthAction = ILoginAction | IRolesAction | IUserInfoAction | IEmailAction | IRememberAction | IOrgRolesAction | ITimeoutAction;

export interface IState {
	accessToken: undefined | string;
	refreshToken: undefined | string;
	loggedIn: boolean;
	userInfo: IUserInfo | undefined;
	roleList: GlobalRole[];
	orgRoles: Record<string, OrgRoles>;
	userEmail: string | undefined;
	rememberMe: boolean;
	timeout: boolean;
}

export const initialState: IState = {
	accessToken: undefined,
	refreshToken: undefined,
	loggedIn: false,
	userInfo: undefined,
	roleList: [],
	userEmail: undefined,
	rememberMe: false,
	orgRoles: {},
	timeout: false,
};

export const reducer: Reducer<IState, AuthAction | GlobalAction> = (state = initialState, action) => {
	switch (action.type) {
		case 'auth/LOGIN': {
			return {
				...state,
				accessToken: action.accessToken,
				refreshToken: action.refreshToken,
				loggedIn: true,
				timeout: false,
			};
		}
		case 'auth/TIMEOUT': {
			return {
				...state,
				timeout: action.timeout,
			};
		}
		case 'auth/USER_INFO': {
			return {
				...state,
				userInfo: action.userInfo,
			};
		}
		case 'auth/ROLES': {
			return {
				...state,
				roleList: action.roleList,
			};
		}
		case 'auth/ORG_ROLES': {
			const orgRoles = {...state.orgRoles};
			orgRoles[action.org._id] = action.role;
			return {
				...state,
				orgRoles,
			};
		}
		case 'auth/EMAIL': {
			return {
				...state,
				userEmail: action.userEmail,
			};
		}
		case 'auth/REMEMBER': {
			return {
				...state,
				rememberMe: action.rememberMe,
			};
		}
		case 'global/LOGOUT': {
			return {
				...state,
				accessToken: undefined,
				refreshToken: undefined,
				loggedIn: false,
				userInfo: undefined,
				roleList: [],
				orgRoles: {},
				timeout: false,
			};
		}
		case 'global/RESET':
			return initialState;
		default:
			return state;
	}
};
