import React from 'react';

import {withTranslation, WithTranslation} from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {withStyles, Theme, createStyles, WithStyles} from '@material-ui/core/styles';
import {Grid, Button, Menu, MenuItem, ListItemIcon, ListItemText, Hidden} from '@material-ui/core';
import {IReduxState} from 'src/reducers';
import {connect} from 'react-redux';
import productLogo from '../res/product-logo.png';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CodeIcon from '@material-ui/icons/Code';
import WarningIcon from '@material-ui/icons/Warning';
import UpdateIcon from '@material-ui/icons/Update';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {styles as commonStyles} from '../style';
import {Home, PersonAdd, Settings, ExitToApp, SupervisorAccount, Person, DescriptionOutlined, Help} from '@material-ui/icons';
import {IUserInfo} from 'src/api/interface/user';
import {haveGlobalRole, haveOrgRole} from 'src/api/interface/roles';
import {podopointGrid, podopointTopMenuHighLight} from 'src/constants/colors';
import {IWithServiceWorker, withServiceWorker} from 'src/ServiceWorkerProvider';

const styles = (theme: Theme) =>
	createStyles({
		...commonStyles(theme),
		...{
			root: {
				flexGrow: 1,
			},
			orgInfo: {
				flexGrow: 1,
				padding: theme.spacing(2),
				color: theme.palette.text.secondary,
				display: 'flex',
				alignItems: 'center',
			},
			title: {
				display: 'inline',
				marginRight: theme.spacing(2),
				marginLeft: theme.spacing(2),
			},
			productIcon: {
				height: '32px',
			},
			warningColor: {
				color: theme.palette.warning.main,
			},
			userEmail: {
				color: podopointGrid,
			},
			navTextButton: {
				color: podopointGrid,
				'&:hover': {
					color: podopointTopMenuHighLight,
				},
				'&.active': {
					color: podopointTopMenuHighLight,
				},
			},
		},
	});

interface IMatchParams {
	orgId: string;
}
type OwnProps = RouteComponentProps<IMatchParams> & {
	userInfo?: IUserInfo;
	isLoggedIn: boolean;
	onLogout: () => Promise<void>;
	onShowHelp: () => void;
};

type Props = IPropsState & OwnProps & WithTranslation & WithStyles & RouteComponentProps & IWithServiceWorker; // ActionList

type State = {
	activeItem: string | undefined;
	orgAdminMenuAnchorEl: null | HTMLElement;
	accountMenuAnchorEl: null | HTMLElement;
};

class TopMenu extends React.Component<Props, State> {
	unregisterCb: () => void;

	constructor(props: Props) {
		super(props);

		this.state = {
			activeItem: undefined,
			orgAdminMenuAnchorEl: null,
			accountMenuAnchorEl: null,
		};

		this.handleNavigation = this.handleNavigation.bind(this);
		this.updateActiveItem = this.updateActiveItem.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
	}

	componentDidMount() {
		this.updateActiveItem(this.props.location.pathname);
		this.unregisterCb = this.props.history.listen((location, action) => {
			this.updateActiveItem(location.pathname);
		});
	}

	updateActiveItem(pathname: string) {
		const orgId = this.props.userOrgInfo?._id;
		if (orgId) {
			let selected;
			if (pathname === `/org/${orgId}/customer`) {
				selected = 'home';
			}
			if (pathname === `/org/${orgId}/editcustomer/create`) {
				selected = 'newcustomer';
			}
			if (pathname === `/org/${orgId}`) {
				selected = 'settings';
			}
			if (pathname === `/org/${orgId}/customers`) {
				selected = 'settings';
			}
			if (pathname === `/org/${orgId}/reports`) {
				selected = 'settings';
			}
			this.setState({
				activeItem: selected,
			});
		}
	}

	componentWillUnmount() {
		this.unregisterCb();
	}

	handleNavigation(e: React.MouseEvent<HTMLButtonElement>) {
		const {currentTarget} = e;
		const orgId = this.props.userOrgInfo?._id;

		if (currentTarget && currentTarget.name !== undefined) {
			switch (currentTarget.name) {
				case 'home':
					if (orgId) {
						this.props.history.push(`/org/${orgId}/customer`);
					} else {
						// Failsafe if navigation bar is shown in a situation where org id has not been set.
						this.props.history.push('/org/');
					}
					break;
				case 'newcustomer':
					if (orgId) {
						this.props.history.push(`/org/${orgId}/editcustomer/create`);
					}
					break;
				case 'settings':
					this.props.history.push('/settings');
					break;
				case 'organization':
					if (orgId) {
						this.props.history.push(`/org/${orgId}`);
					}
					break;
				case 'customers':
					if (orgId) {
						this.props.history.push(`/org/${orgId}/customers`);
					}
					break;
				case 'logout':
					this.props.onLogout();
					break;
				case 'orgadmin':
					this.props.history.push('/admin/org');
					break;
				case 'switch_org':
					this.props.history.push('/org');
					break;
				case 'memberadmin':
					this.props.history.push('/admin/members');
					break;
				case 'check_update':
					if (this.props.serviceWorkerUpdate) {
						this.props.serviceWorkerUpdate();
					}
					break;
				case 'reports':
					if (orgId) {
						this.props.history.push(`/org/${orgId}/reports`);
					}
					break;
				case 'help':
					this.props.onShowHelp();
					break;
				default:
					break;
			}
		}
		// close menus anyway
		this.setState({
			orgAdminMenuAnchorEl: null,
			accountMenuAnchorEl: null,
		});
	}

	getClass(item: string) {
		let className = this.props.classes.navTextButton;

		if (item === this.state.activeItem) {
			className += ' active';
		}
		return className;
	}

	render() {
		const {t, isLoggedIn, userInfo, classes, roleList, orgRoleList, userOrgInfo, haveMultipleOrg, serviceWorkerState} = this.props;
		const {orgAdminMenuAnchorEl, accountMenuAnchorEl} = this.state;
		const haveUpdate = serviceWorkerState && serviceWorkerState === 'installed' ? true : false;

		return (
			<div className={classes.root}>
				<AppBar position="static">
					<Toolbar>
						<div className={classes.orgInfo}>
							<img className={classes.productIcon} src={productLogo} alt="" height="40px" />
						</div>

						{isLoggedIn && (
							<Grid container={true} alignItems="center" justify="flex-end" spacing={2}>
								{/* 								<Grid item={true}>
									<Typography className={classes.userEmail} variant="body1">
										{userInfo?.email}
									</Typography>
								</Grid> */}
								<Grid item={true}>
									<Button size="medium" className={this.getClass('home')} name="home" onClick={this.handleNavigation} color="inherit" startIcon={<Home />}>
										{t('start_page')}
									</Button>
								</Grid>
								{userOrgInfo && (
									<Hidden smDown={true}>
										<Grid item={true}>
											<Button
												size="medium"
												className={this.getClass('newcustomer')}
												name="newcustomer"
												onClick={this.handleNavigation}
												color="inherit"
												startIcon={<PersonAdd />}
											>
												{t('add_new_customer')}
											</Button>
										</Grid>
									</Hidden>
								)}

								{haveOrgRole('ORG_USER_LIST', orgRoleList) ? (
									<Grid item={true}>
										<Button
											size="medium"
											className={this.getClass('settings')}
											onClick={(event) => this.setState({orgAdminMenuAnchorEl: event.currentTarget})}
											color="inherit"
											startIcon={<Settings />}
											endIcon={<KeyboardArrowDownIcon fontSize="small" />}
										>
											{t('administration')}
										</Button>
										<Menu
											anchorEl={orgAdminMenuAnchorEl}
											keepMounted={true}
											open={Boolean(orgAdminMenuAnchorEl)}
											onClose={() => this.setState({orgAdminMenuAnchorEl: null})}
										>
											<MenuItem name="organization" component="button" onClick={this.handleNavigation}>
												<ListItemIcon>
													<SupervisorAccount color="primary" fontSize="small" />
												</ListItemIcon>
												<ListItemText primary={t('organization')} />
											</MenuItem>
											<MenuItem name="customers" component="button" onClick={this.handleNavigation}>
												<ListItemIcon>
													<Person color="primary" fontSize="small" />
												</ListItemIcon>
												<ListItemText primary={t('customers')} />
											</MenuItem>
											<MenuItem name="reports" component="button" onClick={this.handleNavigation}>
												<ListItemIcon>
													<DescriptionOutlined color="primary" fontSize="small" />
												</ListItemIcon>
												<ListItemText primary={t('reports')} />
											</MenuItem>
										</Menu>
									</Grid>
								) : null}

								<Grid item={true}>
									<Button
										size="medium"
										className={this.getClass('logout')}
										name="logout"
										onClick={(event) => this.setState({accountMenuAnchorEl: event.currentTarget})}
										color="inherit"
										startIcon={haveUpdate ? <WarningIcon className={classes.warningColor} /> : <AccountBoxIcon />}
										endIcon={<KeyboardArrowDownIcon fontSize="small" />}
									>
										{(userInfo && userInfo.name) || t('unknown')}
									</Button>
									<Menu
										anchorEl={accountMenuAnchorEl}
										keepMounted={true}
										open={Boolean(accountMenuAnchorEl)}
										onClose={() => this.setState({accountMenuAnchorEl: null})}
									>
										<MenuItem name="settings" component="button" onClick={this.handleNavigation}>
											<ListItemIcon>
												<Settings color="primary" fontSize="small" />
											</ListItemIcon>
											<ListItemText primary={t('settings')} />
										</MenuItem>
										{haveMultipleOrg && (
											<MenuItem name="switch_org" component="button" onClick={this.handleNavigation}>
												<ListItemIcon>
													<CodeIcon color="primary" fontSize="small" />
												</ListItemIcon>
												<ListItemText primary={t('switch_org')} />
											</MenuItem>
										)}
										{haveGlobalRole('ORG_LIST', roleList) ? (
											<MenuItem name="orgadmin" component="button" onClick={this.handleNavigation}>
												<ListItemIcon>
													<SupervisorAccount color="primary" fontSize="small" />
												</ListItemIcon>
												<ListItemText primary={t('organizations')} />
											</MenuItem>
										) : null}
										{haveGlobalRole('ORG_LIST', roleList) ? (
											<MenuItem name="memberadmin" component="button" onClick={this.handleNavigation}>
												<ListItemIcon>
													<PersonAdd color="primary" fontSize="small" />
												</ListItemIcon>
												<ListItemText primary={t('users')} />
											</MenuItem>
										) : null}
										{haveUpdate ? (
											<MenuItem component="button" onClick={this.handleUpdate}>
												<ListItemIcon>
													<UpdateIcon className={classes.warningColor} fontSize="small" />
												</ListItemIcon>
												<ListItemText primary={t('update_waiting')} />
											</MenuItem>
										) : (
											<MenuItem name="check_update" component="button" onClick={this.handleNavigation}>
												<ListItemIcon>
													<SystemUpdateAltIcon color="primary" fontSize="small" />
												</ListItemIcon>
												<ListItemText primary={t('check_update')} />
											</MenuItem>
										)}
										<MenuItem name="help" component="button" onClick={this.handleNavigation}>
											<ListItemIcon>
												<Help color="primary" fontSize="small" />
											</ListItemIcon>
											<ListItemText primary={t('help')} />
										</MenuItem>
										<MenuItem name="logout" component="button" onClick={this.handleNavigation}>
											<ListItemIcon>
												<ExitToApp color="primary" fontSize="small" />
											</ListItemIcon>
											<ListItemText primary={t('logout')} />
										</MenuItem>
									</Menu>
								</Grid>
							</Grid>
						)}
					</Toolbar>
				</AppBar>
			</div>
		);
	}
	private handleUpdate() {
		// skip wait and update
		this.props.serviceWorkerSkipWait && this.props.serviceWorkerSkipWait();
		window.location.reload(true);
	}
}

const getOrgFromPath = (pathname: string): string | undefined => {
	const pathParts = pathname.split('/');
	const orgIndex = pathParts.findIndex((pathPart) => pathPart === 'org');

	if (orgIndex > -1 && orgIndex + 1 < pathParts.length) {
		return pathParts[orgIndex + 1];
	}
	return;
};

const mapStateToProps = (state: IReduxState, ownProps: OwnProps) => {
	const orgId = getOrgFromPath(ownProps.location.pathname);
	let orgInfo;
	if (orgId) {
		orgInfo = (state.org.userOrgList || []).find((org) => orgId === org._id);
	}
	return {
		userOrgInfo: orgInfo,
		roleList: state.auth.roleList || [],
		orgRoleList: (orgId && state.auth.orgRoles[orgId] && state.auth.orgRoles[orgId].roles) || [],
		haveMultipleOrg: state.org.userOrgList.length > 1,
	};
};

type IPropsState = ReturnType<typeof mapStateToProps>;

export default withRouter(connect(mapStateToProps)(withStyles(styles)(withTranslation()(withServiceWorker(TopMenu)))));
