import { Action, Reducer } from 'redux';
import { UserBase, UserDetail } from '../api/interface/admin/user';
import { GlobalAction } from 'src/constants/actions';

type Types = 'user/LIST' | 'user/DETAIL';

interface IListAction extends Action<Types> {
	type: 'user/LIST';
	users: UserBase[];
	
}
interface IDetailAction extends Action<Types> {
	type: 'user/DETAIL';
	user: UserDetail;
}

export type UserAction = IListAction | IDetailAction;

export interface IState {
	users: UserBase[];
	user: UserDetail | undefined;
}

export const initialState: IState = {
	users: [],
	user: undefined,
};

export const reducer: Reducer<IState, UserAction | GlobalAction> = (state = initialState, action) => {
	switch (action.type) {
		case 'user/LIST': {
			return {
				...state,
				users: action.users,
			};
		}
		case 'user/DETAIL': {
			return {
				...state,
				user: action.user,
			};
		}
		case 'global/LOGOUT':
			return initialState;
		case 'global/RESET':
			return initialState;
		default:
			return state;
	}
};