import {Doc} from './mongo';

export const globalRoleCollectionList = ['user', 'superadmin'] as const;
export type RoleCollection = typeof globalRoleCollectionList[number];
export const isRoleCollection = (data: any): data is RoleCollection => {
	return globalRoleCollectionList.findIndex((r) => r === data) !== -1;
};

export const orgRoleCollectionList = ['nurse', 'receptionist', 'admin'] as const;
export type OrgRoleColletion = typeof orgRoleCollectionList[number];

export interface IOrgRoles {
	roles: OrgRole[];
	roleCollection: OrgRoleColletion;
}
export type OrgRoles = Doc<IOrgRoles>;

export const globalRoles = ['ORG_LIST', 'ORG_READ', 'ORG_ADD', 'ORG_MOD', 'ORG_DEL', 'USER_LIST', 'USER_READ', 'USER_ADD', 'USER_MOD', 'USER_DEL'] as const;
export type GlobalRole = typeof globalRoles[number];
export const haveGlobalRole = (role: GlobalRole | string, roles: GlobalRole[]): boolean => {
	return roles.findIndex((r) => r === role) !== -1;
};

export const orgRoles = [
	'ORG_ORG_LIST',
	'ORG_ORG_READ',
	'ORG_ORG_MOD',
	'ORG_USER_LIST',
	'ORG_USER_READ',
	'ORG_USER_ADD',
	'ORG_USER_MOD',
	'ORG_USER_DEL',
	'ORG_CUSTOMER_LIST',
	'ORG_CUSTOMER_READ',
	'ORG_CUSTOMER_ADD',
	'ORG_CUSTOMER_MOD',
	'ORG_CUSTOMER_DEL',
	'ORG_CUSTOMER_TREATMENT_LIST',
	'ORG_CUSTOMER_TREATMENT_READ',
	'ORG_CUSTOMER_TREATMENT_ADD',
	'ORG_CUSTOMER_TREATMENT_MOD',
	'ORG_CUSTOMER_TREATMENT_DEL',
] as const;
export type OrgRole = typeof orgRoles[number];

export const haveOrgRole = (role: OrgRole | string, roles: OrgRole[]): boolean => {
	return roles.findIndex((r) => r === role) !== -1;
};
