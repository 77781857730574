/**
 * cacheMatch
 * @param req
 * @param options
 * @throws Error if no cache data and browser is offline
 */
export const cacheMatch = async (req: Request, options?: {ifNoneMatch?: boolean}): Promise<Response | undefined> => {
	if (typeof window !== 'undefined' && window.caches) {
		const cache = await window.caches.open('default');
		const resp = await cache.match(req);
		if (resp && options && options.ifNoneMatch) {
			const etag = resp.headers.get('ETag');
			if (etag) {
				req.headers.set('if-none-match', etag);
			}
		}
		if (!navigator.onLine && !resp) {
			throw new Error('browser is offline and no data found');
		}
		return resp;
	}
	return undefined;
};

export const cacheStore = async (req: Request, res: Response): Promise<void> => {
	if (typeof window !== 'undefined' && window.caches && res.status === 200) {
		const cache = await window.caches.open('default');
		req.headers.delete('Authorization');
		return cache.put(req, res.clone());
	}
};

export const cleanCache = async (basePath: string, matchUrl: string[]) => {
	if (typeof window !== 'undefined' && window.caches) {
		const cache = await window.caches.open('default');
		const notFound = (await cache.keys()).filter((res) => res.url.startsWith(basePath) && matchUrl.indexOf(res.url) === -1);
		for (const req of notFound) {
			await cache.delete(req);
		}
	}
};

export const clearOne = async (fullPath: string) => {
	if (typeof window !== 'undefined' && window.caches) {
		const cache = await window.caches.open('default');
		const found = (await cache.keys()).find((res) => res.url === fullPath);
		if (found) {
			await cache.delete(found);
		}
	}
};

export const deleteCache = async (): Promise<void> => {
	if (typeof window !== 'undefined' && window.caches) {
		await window.caches.delete('default');
	}
};

export const isOnline = () => {
	if (typeof window !== 'undefined' && window.navigator) {
		return window.navigator.onLine;
	}
	return true;
};
