import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withTranslation, WithTranslation} from 'react-i18next';

interface IErrorDialogProps {
	open: boolean;
	error: string | undefined;
	onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ErrorDialog: React.SFC<IErrorDialogProps & WithTranslation> = ({open, t, error, onClose}) => (
	<Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
		<DialogTitle>{t('error')}</DialogTitle>
		<DialogContent>
			<DialogContentText>{error}</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button color="primary" variant="contained" autoFocus={true} onClick={onClose}>
				{t('ok')}
			</Button>
		</DialogActions>
	</Dialog>
);
export default withTranslation()(ErrorDialog);
