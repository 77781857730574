import 'fastestsmallesttextencoderdecoder';
import {register, listen} from './serviceWorkerRegistration';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import React from 'react'; // eslint-disable-line no-unused-vars
import 'react-app-polyfill/ie9';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import App from './App';
import {ServiceWorkerProvider} from './ServiceWorkerProvider';
import theme from './theme';
import moment from 'moment';
import 'moment/locale/fi';

register();

moment.locale('fi');

Promise.all([
	import('./configureStore' /* webpackChunkName: "configurestore" */),
	import('./i18n' /* webpackChunkName: "i18n" */),
	import('cross-fetch/polyfill' /* webpackChunkName: "fetch" */),
]).then((loaded) => {
	const [configureStore, i18n] = loaded;
	const {store, persistor} = configureStore.default();
	ReactDOM.render(
		<ServiceWorkerProvider listener={listen}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<I18nextProvider i18n={i18n.default}>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<App />
						</ThemeProvider>
					</I18nextProvider>
				</PersistGate>
			</Provider>
		</ServiceWorkerProvider>,
		document.getElementById('root'),
	);
});
