import {Action, Reducer} from 'redux';
import {GlobalAction} from '../constants/actions';

type Types = 'app/LOADING' | 'app/ERROR' | 'app/DATA' | 'app/SHOW_HELP';

export interface IStatusData {
	alive: boolean;
	database: boolean;
}

interface IShowHelpAction extends Action<Types> {
	type: 'app/SHOW_HELP';
	showHelp: boolean;
}

interface ILoadingAction extends Action<Types> {
	type: 'app/LOADING';
	isLoading: boolean;
}

interface IErrorAction extends Action<Types> {
	type: 'app/ERROR';
	error: string | undefined;
}

interface IStatusAction extends Action<Types> {
	type: 'app/DATA';
	status: IStatusData | undefined;
}

export type AppAction = ILoadingAction | IStatusAction | IErrorAction | IShowHelpAction;

export interface IState {
	showHelp: boolean;
	isLoading: boolean;
	status: IStatusData | undefined;
	error: string | undefined;
}

export const initialState: IState = {
	error: undefined,
	isLoading: false,
	status: undefined,
	showHelp: false,
};

export const reducer: Reducer<IState, AppAction | GlobalAction> = (state = initialState, action) => {
	switch (action.type) {
		case 'app/LOADING': {
			return {
				...state,
				isLoading: action.isLoading,
			};
		}
		case 'app/ERROR': {
			return {
				...state,
				error: action.error,
			};
		}
		case 'app/DATA': {
			return {
				...state,
				status: action.status,
			};
		}
		case 'app/SHOW_HELP': {
			return {
				...state,
				showHelp: action.showHelp,
			};
		}
		case 'global/RESET':
			return initialState;
		default:
			return state;
	}
};
