import * as React from 'react';
import {Dialog, DialogTitle, DialogContent, WithStyles, withStyles, IconButton} from '@material-ui/core';
import {styles} from '../style';
import {withTranslation, WithTranslation} from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import LoginComponent from './LoginComponent';
import {RouteComponentProps, withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {IReduxState, RootThunkDispatch} from '../reducers';
import {doLogout} from 'src/actions/authActions';

interface IProps {
	open: boolean;
}

type Props = IProps & IPropsState & ActionList & WithStyles & WithTranslation & RouteComponentProps;

interface IState {
	username: string;
}

class LoginDialog extends React.Component<Props, IState> {
	constructor(props: Props) {
		super(props);
		this.state = {
			username: '',
		};
		this.handleLogout = this.handleLogout.bind(this);
	}

	render() {
		const {open, t, classes} = this.props;
		return (
			<Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle>
					{t('login')}
					<IconButton aria-label="close" className={classes.dialogCloseButton} onClick={this.handleLogout}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<LoginComponent type="timeout" />
				</DialogContent>
			</Dialog>
		);
	}

	private async handleLogout() {
		await this.props.doLogout();
		this.props.history.push('/login');
	}
}

const mapStateToProps = (state: IReduxState) => {
	return {};
};
type IPropsState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: RootThunkDispatch) =>
	bindActionCreators(
		{
			doLogout,
		},
		dispatch,
	);

type ActionList = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(withRouter(LoginDialog))));
