import {cacheMatch, cacheStore} from './commonCache';

interface IConfig {
	api_base: string;
	client_id: string;
}

const getApiBaseFromHostname = (location: Location) => {
	switch (location.hostname) {
		case 'kinttupiste.salodev.ajr.fi':
			return 'https://kinttupiste-backend.salodev.ajr.fi/api';
		default:
			return 'http://localhost:9325/api';
	}
};

let currentConfig: IConfig | undefined;
const getConfig = async (): Promise<IConfig> => {
	if (currentConfig) {
		return currentConfig;
	}
	if (process.env.NODE_ENV === 'test') {
		currentConfig = {
			api_base: 'http://localhost:9325/api',
			client_id: '9441beed-95b8-47ce-85cd-53a56ae0ab77',
		};
	} else {
		const req = new Request('./config.json');
		const cacheRes = await cacheMatch(req, {ifNoneMatch: true});
		if (cacheRes) {
			currentConfig = (await cacheRes.json()) as IConfig;
		}
		if (navigator.onLine) {
			const res = await fetch(req);
			if (res.status === 200) {
				await cacheStore(req, res);
				currentConfig = (await res.json()) as IConfig;
			} else if (res.status === 304) {
				// ignore, cached config still ok
			} else {
				console.warn(`config.json failed to load from ${res.url} with ${res.status}`);
				// if did go totally wrong, get options from browser hostname
				currentConfig = {
					api_base: getApiBaseFromHostname(window.location),
					client_id: '9441beed-95b8-47ce-85cd-53a56ae0ab77',
				};
			}
		}
	}
	if (!currentConfig) {
		throw new Error('configuration loading failed');
	}
	return currentConfig;
};

let currentApiBase: string | undefined;
export const getApiBase = async () => {
	if (currentApiBase) {
		return currentApiBase;
	}
	if (process.env.REACT_APP_API_BASE) {
		console.debug('getApiBase from process.env.REACT_APP_API_BASE');
		currentApiBase = process.env.REACT_APP_API_BASE;
	} else {
		const {api_base} = await getConfig();
		console.debug('getApiBase from config.json');
		currentApiBase = api_base;
	}
	return currentApiBase;
};

let currentClientId: string | undefined;
export const getClientId = async () => {
	if (currentClientId) {
		return currentClientId;
	}
	if (process.env.REACT_APP_CLIENT_ID) {
		currentClientId = process.env.REACT_APP_CLIENT_ID;
	} else {
		const {client_id} = await getConfig();
		currentClientId = client_id;
	}
	return currentClientId;
};
