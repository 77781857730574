import {Action, Reducer} from 'redux';
import {AdminOrgBase, AdminOrgDetail, AdminOrgMember} from '../api/interface/admin/org';
import {GlobalAction} from '../constants/actions';
import {OrgMember, OrgInfo} from '../api/interface/org';

type Types = 'org/ADMIN_LIST' | 'org/ADMIN_READ' | 'org/ADMIN_MEMBERS' | 'org/USER_LIST' | 'org/USER_MEMBERS';

interface IAdminListAction extends Action<Types> {
	type: 'org/ADMIN_LIST';
	orgList: AdminOrgBase[];
}

interface IAdminReadAction extends Action<Types> {
	type: 'org/ADMIN_READ';
	org: AdminOrgDetail | undefined;
}

interface IAdminMembersAction extends Action<Types> {
	type: 'org/ADMIN_MEMBERS';
	orgMembers: AdminOrgMember[];
}

interface IUserListAction extends Action<Types> {
	type: 'org/USER_LIST';
	userOrgList: OrgInfo[];
}

interface IUserMembersAction extends Action<Types> {
	type: 'org/USER_MEMBERS';
	userOrgMembers: OrgMember[];
}


export type OrgAction = IAdminListAction | IAdminReadAction | IAdminMembersAction | IUserListAction | IUserMembersAction;
export interface IState {
	orgList: AdminOrgBase[];
	org: AdminOrgDetail | undefined;
	orgMembers: AdminOrgMember[];
	userOrgList: OrgInfo[];
	userOrgMembers: OrgMember[];
}

export const initialState: IState = {
	orgList: [],
	org: undefined,
	orgMembers: [],
	userOrgList: [],
	userOrgMembers: [],
};

export const reducer: Reducer<IState, OrgAction | GlobalAction> = (state = initialState, action) => {
	switch (action.type) {
		case 'org/ADMIN_LIST': {
			return {
				...state,
				orgList: action.orgList,
			};
		}
		case 'org/ADMIN_READ': {
			return {
				...state,
				org: action.org,
			};
		}
		case 'org/ADMIN_MEMBERS': {
			return {
				...state,
				orgMembers: action.orgMembers,
			};
		}
		case 'org/USER_LIST': {
			return {
				...state,
				userOrgList: action.userOrgList,
			};
		}
		case 'org/USER_MEMBERS': {
			return {
				...state,
				userOrgMembers: action.userOrgMembers,
			};
		}
		case 'global/LOGOUT':
			return initialState;
		case 'global/RESET':
			return initialState;
		default:
			return state;
	}
};
