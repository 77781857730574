import {podopointRed, podopointTextPrimary, podopointTextSecondary, podopointPrimary, podopointSecondary} from './constants/colors';
import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {PaletteColor} from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createMuiTheme' {
	// tslint:disable-next-line:interface-name
	interface Theme {
		buttons: {
			blue: PaletteColor;
			green: PaletteColor;
			orange: PaletteColor;
			gray: PaletteColor;
			red: PaletteColor;
		};
	}
	// tslint:disable-next-line:interface-name
	interface ThemeOptions {
		buttons: {
			blue: PaletteColor;
			green: PaletteColor;
			orange: PaletteColor;
			gray: PaletteColor;
			red: PaletteColor;
		};
	}
}

const options = {
	factor: 24,
	disableAlign: true,
	breakPoints: ['xs', 'sm', 'md', 'lg', 'xl'],
};
// A custom theme for this app
let theme = createMuiTheme({
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'abbr[title]': {
					textDecorationStyle: 'dashed',
				},
			},
		},
		MuiInputLabel: {
			root: {
				fontStyle: 'italic',
			},
		},
		MuiButton: {
			root: {
				borderRadius: '2em', // default = 4px
			},
		},
		MuiCheckbox: {
			colorSecondary: {
				color: '#0083C5',
				'&$checked': {
					color: '#0083C5',
				},
			},
		},
		MuiRadio: {
			root: {
				color: '#0083C5',
				'&$checked': {
					color: '#D9EDF8​',
				},
			},
		},
		MuiSelect: {
			root: {
				backgroundColor: 'white',
			},
			outlined: {
				padding: '6px',
				paddingLeft: '10px',
			},
		},

		MuiOutlinedInput: {
			root: {
				backgroundColor: 'white',
			},
			inputMarginDense: {
				padding: '6px !important',
				paddingLeft: '10px !important',
				paddingRight: '30px !important',
			},
		},
	},
	palette: {
		primary: {
			main: podopointPrimary,
		},
		secondary: {
			main: podopointSecondary,
		},
		error: {
			main: podopointRed,
		},
		background: {
			// default: '#F7FCFF​',
			default: '#f6fbfe',
		},
		text: {
			primary: podopointTextPrimary,
			secondary: podopointTextSecondary,
		},
		warning: {
			main: '#F2865E',
		},
	},
	typography: {
		fontFamily: '"Cabin", sans-serif',
		fontSize: 15,
	},
	buttons: {
		blue: {
			main: '#0083C5',
			dark: '#005794',
			light: '#5ab2f8',
			contrastText: '#F7FCFF',
		},
		green: {
			main: '#00ad8a',
			dark: '#007d5d',
			light: '#57e0ba',
			contrastText: '#F7FCFF',
		},
		orange: {
			main: '#f2865e',
			dark: '#bb5733',
			light: '#ffb78c',
			contrastText: '#F7FCFF',
		},
		red: {
			main: '#ff1744',
			dark: '#b2102f',
			light: '#ff4569',
			contrastText: '#F7FCFF',
		},
		gray: {
			main: '#8999AD',
			dark: '#5b6b7e',
			light: '#b9cadf',
			contrastText: '#F7FCFF',
		},
	},
});

theme = responsiveFontSizes(theme, options);

export default theme;
