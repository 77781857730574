import * as React from 'react';
import {
	Dialog,
	WithStyles,
	withStyles,
	Container,
	AppBar,
	Toolbar,
	IconButton,
	Theme,
	createStyles,
	Grid,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@material-ui/core';
import {styles as commonStyles} from '../style';
import {withTranslation, WithTranslation} from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import productLogo from '../res/product-logo.png';
import backgroundImage from '../res/kinttupiste.png';
// const ReactMarkdown = require('react-markdown');
import ReactMarkdown from 'react-markdown';
import {ExpandMore} from '@material-ui/icons';
import {podopointTextSecondary} from 'src/constants/colors';

interface IProps {
	open: boolean;
	onClose: () => void;
}

type Props = IProps & WithStyles & WithTranslation;

interface IHelpQuestion {
	question: string;
	answer: string; // Can contain markdown
}

interface IState {
	helpQuestions: IHelpQuestion[];
}

const styles = (theme: Theme) =>
	createStyles({
		...commonStyles(theme),
		...{
			orgInfo: {
				flexGrow: 1,
				padding: theme.spacing(2),
				color: theme.palette.text.secondary,
				display: 'flex',
				alignItems: 'center',
			},

			productIcon: {
				height: '32px',
			},
			paper: {
				color: theme.palette.text.secondary,
				padding: theme.spacing(2),
				margin: theme.spacing(2),
			},
			root: {
				flexGrow: 1,
			},
		},
	});

class HELPDialog extends React.Component<Props, IState> {
	constructor(props: Props) {
		super(props);
		this.state = {
			helpQuestions: this.prepareHelpQuestions(),
		};
	}

	prepareHelpQuestions(): IHelpQuestion[] {
		const {t} = this.props;

		let helpQuestions: IHelpQuestion[] = [];

		for (let i = 1; i < 10; i++) {
			helpQuestions.push({
				question: t(`help:q${i}`),
				answer: t(`help:a${i}`),
			});
		}
		return helpQuestions;
	}

	render() {
		const {open, onClose, classes} = this.props;

		return (
			<Dialog
				PaperProps={{
					style: {
						backgroundColor: '#f6fbfe',
					},
				}}
				open={open}
				fullScreen={true}
				onClose={onClose}
				aria-labelledby="gdpr-dialog-title"
				aria-describedby="gdpr-dialog-description"
			>
				<AppBar position="static">
					<Toolbar>
						<div className={classes.orgInfo}>
							<img alt="" className={classes.productIcon} src={productLogo} height="40px" />
						</div>
						<IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Grid className={classes.rootMaxHeight} container={true} component="main" justify="center" direction="row" alignItems="stretch">
					<Grid item={true} xs={12} md={8}>
						<Container maxWidth="lg" style={{marginTop: '18px'}}>
							{this.state.helpQuestions.map((helpQuestion, i) => (
								<Accordion key={i}>
									<AccordionSummary expandIcon={<ExpandMore />} aria-controls={`panel-${i}-content`} id={`panel-${i}-header`}>
										<Typography color="textSecondary" variant="body1">
											{helpQuestion.question}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<div style={{color: podopointTextSecondary}}>
											<ReactMarkdown source={helpQuestion.answer} />
										</div>
									</AccordionDetails>
								</Accordion>
							))}
						</Container>
					</Grid>
					<Grid item={true} xs={false} md={4} className={classes.loginImage} style={{backgroundImage: `url(${backgroundImage})`}} />
				</Grid>
			</Dialog>
		);
	}
}

export default withStyles(styles)(withTranslation()(HELPDialog));
