import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import {Button, Typography} from '@material-ui/core';
import {RootThunkDispatch, IReduxState} from 'src/reducers';
import {bindActionCreators} from 'redux';
import {doReset} from '../actions/globalActions';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';

interface IProps {
	error: Error;
}

type Props = IProps & WithTranslation & ActionList & IPropsState & RouteComponentProps;

class ErrorView extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleReset = this.handleReset.bind(this);
	}
	render() {
		const {t, error} = this.props;
		return (
			<div>
				<Helmet>
					<title>Fatal error</title>
				</Helmet>
				<div className="App-intro">
					<h1 style={{color: 'red'}}>{t('fatal_error')}</h1>
					<Typography>{error && error.message ? error.message : 'no error'}</Typography>
					<Button variant="contained" color="secondary" onClick={this.handleReset}>
						{t('reset')}
					</Button>
				</div>
			</div>
		);
	}
	private async handleReset() {
		await this.props.doReset();
		this.props.history.push('/');
		window.location.reload(true);
	}
}

const mapStateToProps = (state: IReduxState) => ({});
type IPropsState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: RootThunkDispatch) =>
	bindActionCreators(
		{
			doReset,
		},
		dispatch,
	);
type ActionList = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ErrorView)));
