import {GlobalAction} from '../constants/actions';
import {ThunkResult} from 'src/reducers';
import {authLogout} from './authActions';

// dispatcher actions
const doRestAction = (): GlobalAction => {
	return {type: 'global/RESET'};
};

export const doLogoutAction = (): GlobalAction => {
	return {type: 'global/LOGOUT'};
};


// async functions
export const doReset = (): ThunkResult<Promise<void>> => async (dispatch) => {
	await dispatch(authLogout());
	dispatch(doRestAction());
};
