import {Action, combineReducers} from 'redux';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import * as app from './appReducer';
import * as auth from './authReducer';
import * as customer from './customerReducer';
import * as org from './orgReducer';
import * as treatment from './treatmentReducer';
import * as user from './userReducer';

export interface IReduxState {
	app: app.IState;
	auth: auth.IState;
	customer: customer.IState;
	org: org.IState;
	treatment: treatment.IState;
	user: user.IState;
}
/**
 * Combine all initial states
 * @see {@link createStore/default}
 */
export const initialState = {
	app: app.initialState,
	auth: auth.initialState,
	customer: customer.initialState,
	org: org.initialState,
	treatment: treatment.initialState,
	user: user.initialState,
};

/**
 * Combine all reducers with names
 */
export const rootReducer = combineReducers({
	app: app.reducer,
	auth: auth.reducer,
	customer: customer.reducer,
	org: org.reducer,
	treatment: treatment.reducer,
	user: user.reducer,
});

export type ThunkResult<R> = ThunkAction<R, IReduxState, undefined, Action>;

export type RootThunkDispatch = ThunkDispatch<IReduxState, undefined, Action>;
